import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  gql,
  InMemoryCache,
  useApolloClient,
  useQuery,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import React, { Suspense, useCallback, useEffect } from "react"
import Modal from "react-modal"
import { BrowserRouter as Router, Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import "./App.scss"
import { Layout } from "./components/Layout"
import { GET_PROFILE } from "./gqls"
import { usePathClient } from "./hooks/usePathClient"
import { FindPassword, Login } from "./pages/Login"
import { useDispatch } from "react-redux"
import { updateCid } from "./store/reducers/SessionReducer"

export const IS_DEV = process.env.NODE_ENV === "development"

const DEV_URL = IS_DEV && "http://localhost:4000/graphql"

const httpLink = createHttpLink({
  uri: DEV_URL || process.env.REACT_APP_API_URL || "http://localhost:4000/graphql",
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("gdtk")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  }
})

// export const profileVar = makeVar(null);

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions: {
    // query: {
    //   fetchPolicy: 'cache-and-network',
    // },
    // watchQuery: {
    //   fetchPolicy: 'cache-and-network',
    // },
  },
  cache: new InMemoryCache(),
  // {
  //   // typePolicies: {
  //   //   Query: {
  //   //     fields: {
  //   //       profile: {
  //   //         read() {
  //   //           return sessionStorage.getItem('profile')
  //   //         },
  //   //       },
  //   //     },
  //   //   },
  //   // },
  // }),
})

function Logout() {
  const client = useApolloClient()
  const handleLogout = useCallback(async () => {
    sessionStorage.removeItem("gdtk")
    await client.clearStore()
    client.cache.gc()
    setImmediate(() => {
      window.location.pathname = "/login"
      // history.replace('/')
    })
  }, [client])

  useEffect(() => {
    handleLogout()
  }, [handleLogout])
  return <div>logout</div>
}

Modal.setAppElement("#root")

function Gateway() {
  const { data } = useQuery(GET_PROFILE, { errorPolicy: "ignore" })

  const client = data.me.clients[0]
  if (client) {
    return <Redirect to={`/c/${client.cid}/dashboard`} />
  }

  return <Layout>No Clients</Layout>
}

function PrivateRoute({ isSuper = false, component: Component, ...rest }) {
  const { loading, data } = useQuery(GET_PROFILE, {
    fetchPolicy: "cache-only",
  })

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!data?.me && loading) {
          return null
        }
        if (!data.me) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
        if (isSuper && !data.me.isSuper) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location },
              }}
            />
          )
        }

        return <Component />
      }}
    />
  )
}

const DashboardPage = React.lazy(() => import("./pages/DashboardPage"))
const GroupPage = React.lazy(() => import("./pages/GroupPage"))
const GroupListPage = React.lazy(() => import("./pages/GroupListPage"))
const GroupAllSurveyPage = React.lazy(() => import("./pages/GroupAllSurveyPage"))
const MemberResultPage = React.lazy(() => import("./pages/MemberResultPage"))
const SurveyDetailPage = React.lazy(() => import("./pages/SurveyDetailPage"))
const SurveyListPage = React.lazy(() => import("./pages/SurveyListPage"))
const SendSurveyPage = React.lazy(() => import("./pages/SendSurveyPage"))
const ShareResourcePage = React.lazy(() => import("./pages/ShareResourcePage"))
const ManagerPage = React.lazy(() => import("./pages/ManagerPage"))
const ClientPage = React.lazy(() => import("./pages/ClientPage"))

export const MEMBER_CLIENTS = gql`
  query clients($memberId: Int!) {
    clients(memberId: $memberId) {
      id
      cid
      name
      email
      contact
      createdAt
    }
  }
`

function ClientRoute() {
  // const {data} = useQuery(GET_PROFILE, {errorPolicy: 'ignore'})
  const client = usePathClient()
  // const {loading} = useQuery(MEMBER_CLIENTS, {
  //   // fetchPolicy: 'cache-only',
  //   variables: {memberId: data.me.memberId},
  //   skip: !data?.me?.memberId,
  // })
  const match = useRouteMatch()
  const dispatch = useDispatch()
  if (!client) {
    console.log("no client")
    return <Redirect to="/dashboard" />
  } else {
    dispatch(updateCid(client.cid))
  }
  const path = match.path

  return (
    <Switch>
      <Route path={`${path}/dashboard`} component={DashboardPage} />
      <Route path={`${path}/grouptree`} component={GroupPage} />
      <Route path={`${path}/group/:gid`} exact component={GroupListPage} />
      <Route path={`${path}/group/:gid/allsurvey`} component={GroupAllSurveyPage} />
      <Route path={`${path}/group/:gid/mhistory/:mid`} component={MemberResultPage} />
      <Route path={`${path}/survey/:sid/:gid`} component={SurveyDetailPage} />
      <Route path={`${path}/survey`} exact component={SurveyListPage} />
      <Route path={`${path}/send`} component={SendSurveyPage} />
      <Route path={`${path}/share`} component={ShareResourcePage} />
    </Switch>
    // </Suspense>
  )
}

function AuthRoutes() {
  const { loading, error } = useQuery(GET_PROFILE, {
    // fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-only',
    errorPolicy: "ignore",
  })

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error...</div>
  }

  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PrivateRoute path="/c/:cid" component={ClientRoute} />
          <PrivateRoute path="/dashboard" component={Gateway} />
          <PrivateRoute isSuper path="/managers" component={ManagerPage} />
          <PrivateRoute isSuper path="/clients" component={ClientPage} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    </Layout>
  )
}

function Routes() {
  const { loading, error } = useQuery(GET_PROFILE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "ignore",
  })

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error...</div>
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/findpw" component={FindPassword} />
        <Route path="/logout" component={Logout} />
        <PrivateRoute path="/(.+)" component={AuthRoutes} />

        {/* <PrivateRoute path="/c/:cid" component={ClientRoute} />
        <PrivateRoute path="/dashboard" component={Gateway} />
        <PrivateRoute isSuper path="/managers" component={ManagerPage} />
        <PrivateRoute isSuper path="/clients" component={ClientPage} /> */}
        <Redirect from="/" exact to="/dashboard" />
      </Switch>
    </Suspense>
  )
}

// const theme = {
//   // global: { menu: { icon: { color: '#333' } } }
//   global: {
//     colors: {
//       // Overriding existing colors
//       brand: '#1890ff',
//     },
//   },
//   menu: {
//     icons: {
//       color: '#444',
//     },
//   },
// }

const theme = extendTheme({
  colors: {
    brand: {
      100: "#54626F",
      // 100: '#1e3142',
      // 200: '#1e3142',
      // 300: '#1e3142',
      400: "#1e3142",
      500: "#1890ff",
      // 600: '#1890ff',
      // 700: '#1890ff',
      // 800: '#1890ff',
      900: "#1890ff",
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
        // borderRadius: 'none',
      },
      baseStyle: {
        fontWeight: "normal",
        borderRadius: "none",
      },
    },
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      {/* <SnackbarProvider> */}
      <ApolloProvider client={client}>
        <Router>
          <Routes />
        </Router>
      </ApolloProvider>
      {/* </SnackbarProvider> */}
    </ChakraProvider>
  )
}

export default App
