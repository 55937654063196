import { useQuery } from "@apollo/client"
import { GET_PROFILE } from "../gqls"

export function useProfile() {
  const {
    data: { me },
  } = useQuery(GET_PROFILE, { errorPolicy: "ignore", fetchPolicy: "cache-only" })
  const profile = me
  return profile
}
