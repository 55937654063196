import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useMutation, gql, useLazyQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"
import { GET_PROFILE } from "../gqls"
import { useTranslation } from "react-i18next"
import { Menu } from "grommet"
import { langs } from "../i18n"
import "./Login.scss"

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`
const SEND_FINDPW = gql`
  mutation sendFindPw($email: String!) {
    sendFindPwEmail(email: $email)
  }
`

const IS_DEV = process.env.NODE_ENV === "development"

export function Login() {
  const [lang, setLang] = React.useState(sessionStorage.getItem("gdlng") || "en")
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [getProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      console.log("onComplete")
      history.push("/dashboard")
    },
  })
  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data.login) {
        sessionStorage.setItem("gdtk", `Bearer ${data.login}`)
        // i18n.changeLanguage(lang)
        // sessionStorage.setItem("gdlng", lang)
        setTimeout(() => {
          getProfile()
          // setTimeout(() => {
          //   history.push('/')
          // }, 10)
        }, 10)
      } else {
        throw Error("no token")
      }
    },
    // onError: (err) => {
    //   console.log(err)
    // },
  })

  return (
    <div className="login-page">
      <div className="panel">
        <div className="logo" style={{ cursor: "pointer" }} onClick={() => history.push("/dashboard")}>
          {t("DinoDialogue")} Manager
        </div>
        <Formik
          initialValues={IS_DEV ? { email: "m1@ws.io", password: "mypass" } : { email: "", password: "" }}
          // initialValues={{email: '', password: ''}}
          validationSchema={Yup.object({
            email: Yup.string().email(t("login.notValidEmail")).required(t("login.emailIsRequired")),
            password: Yup.string().required(t("login.passwordIsRequired")),
          })}
          onSubmit={(values, { setSubmitting }) => {
            login({ variables: values })
              .then(() => {
                //   setSubmitting(false)
              })
              .catch((err) => {
                if (err.networkError) {
                  window.alert(t("errors.networkError"))
                } else if (["noManagerFound", "passwordNotMatches"].includes(err.message)) {
                  window.alert(t("login." + err.message))
                } else {
                  window.alert(t("errors.unknownError"))
                }

                setSubmitting(false)
              })
          }}
        >
          <Form>
            <div>
              <Field name="email" type="email" placeholder={t("login.email")} autoFocus />
              <div className="err-msg">
                <ErrorMessage name="email" />
              </div>
            </div>
            <div>
              <Field name="password" type="password" placeholder={t("login.password")} />
              <div className="err-msg">
                <ErrorMessage name="password" />
              </div>
            </div>
            <div>
              <Menu
                fill
                className="lang-sel"
                // margin="-4px"
                justifyContent="between"
                focusIndicator={false}
                label={langs.find((l) => l.value === lang).label}
                items={langs.map((l) => ({
                  ...l,
                  onClick: () => {
                    setLang(l.value)
                    i18n.changeLanguage(l.value)
                  },
                }))}
              />
            </div>

            <button className="find-pw" type="button" onClick={() => history.push("/findpw")}>
              {t("login.findPassword")}
            </button>
            <button className="submit" type="submit">
              {t("login.login")}
            </button>
          </Form>
        </Formik>
      </div>
      <div className="copyright">{"© 2020 Paul&Mark. All rights reserved"}</div>
    </div>
  )
}

export function FindPassword() {
  const [lang, setLang] = React.useState(sessionStorage.getItem("gdlng") || "en")
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const [sendFindPw] = useMutation(SEND_FINDPW)

  return (
    <div className="login-page">
      <div className="panel">
        <div className="logo" style={{ cursor: "pointer" }} onClick={() => history.push("/dashboard")}>
          {t("findpw.findPassword")}
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string().email(t("login.notValidEmail")).required(t("login.emailIsRequired")),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log({ values })
            sendFindPw({ variables: { email: values.email } })
              .then(() => {
                window.alert(t("findpw.emailSented"))
                setSubmitting(false)
              })
              .catch((err) => {
                if (err.networkError) {
                  window.alert(t("errors.networkError"))
                } else if (["noManagerFound", "passwordNotMatches"].includes(err.message)) {
                  window.alert(t("login." + err.message))
                } else {
                  window.alert(t("errors.unknownError"))
                }

                setSubmitting(false)
              })
          }}
        >
          <Form>
            <div>
              <Field name="email" type="email" placeholder={t("login.email")} autoFocus />
              <div className="err-msg">
                <ErrorMessage name="email" />
              </div>
            </div>

            <div>
              <Menu
                fill
                className="lang-sel"
                // margin="-4px"
                justifyContent="between"
                focusIndicator={false}
                label={langs.find((l) => l.value === lang).label}
                items={langs.map((l) => ({
                  ...l,
                  onClick: () => {
                    const lang = l.value
                    setLang(lang)
                    sessionStorage.setItem("gdlng", lang)
                    i18n.changeLanguage(lang)
                  },
                }))}
              />
            </div>

            <button className="find-pw" type="button" onClick={() => history.push("/login")}>
              {t("login.login")}
            </button>
            <button className="submit" type="submit">
              {t("findpw.sendEmail")}
            </button>
          </Form>
        </Formik>
      </div>
      <div className="copyright">{"© 2020 Paul&Mark. All rights reserved"}</div>
    </div>
  )
}
