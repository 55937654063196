import { gql } from "@apollo/client"

export const GET_PROFILE = gql`
  query profile {
    me {
      id
      name
      email
      company
      isSuper
      clients {
        id
        cid
        name
        email
        groupTree
      }
    }
  }
`

export const GET_CLIENTS = gql`
  query clients {
    clients {
      id
      cid
      name
      email
      contact
      createdAt
    }
  }
`

export const GET_CLIENTS_BY_MID = gql`
  query clientsByManagerId($managerId: Int!) {
    clients(managerId: $managerId) {
      id
      cid
      name
      email
      contact
      createdAt
    }
  }
`

export const GET_CLIENT = gql`
  query manager($id: Int!) {
    client(id: $id) {
      id
      cid
      name
      email
      contact
    }
  }
`

export const ADD_CLIENT = gql`
  mutation addClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      cid
      name
      email
      contact
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: Int!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      id
      cid
      name
      email
      contact
    }
  }
`

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: Int!) {
    deleteClient(id: $id)
  }
`

// export const ADD_GROUP = gql`
// mutation createGroup($id: Int!, $groupTree: JSON!, $path: [Int!]!, $title:String!) {
//     createGroup(
//     id: $id,
//     groupTree: $groupTree
//     path: $path
//     title: $title
//   ) {
//     id
//     cid
//     name
//     email
//     contact
//     groupTree
//   }
// }
// `

export const GET_MANAGERS = gql`
  query managers {
    managers {
      id
      name
      email
      company
      isSuper
      # clients {
      #   id
      #   cid
      #   name
      #   email
      # }
    }
  }
`
export const GET_MANAGER = gql`
  query manager($id: Int!) {
    manager(id: $id) {
      id
      name
      email
      company
      isSuper
      clients {
        id
        cid
        name
        email
      }
    }
  }
`

export const ADD_MANAGER = gql`
  mutation addManager($input: ManagerInput!) {
    createManager(input: $input) {
      id
      name
      email
      company
      isSuper
      clients {
        id
        cid
        name
        email
      }
    }
  }
`

export const UPDATE_MANAGER = gql`
  mutation updateManager($id: Int!, $input: ManagerEditInput!, $clientIds: [Int!]) {
    updateManager(id: $id, input: $input, clientIds: $clientIds) {
      id
      name
      email
      company
      isSuper
      clients {
        id
        cid
        name
        email
        groupTree
      }
    }
  }
`
export const DELETE_MANAGER = gql`
  mutation deleteManager($id: Int!) {
    deleteManager(id: $id)
  }
`
