import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { merge } from "lodash"

const englishTranslation = {
  DinoDialogue: "Dino Dialogue",
  group: "Group",
  dashboardPage: {
    nthGroupMessage: "Total {{count}} groups are now under management.",
    surveyIntroMessage: "Share ideas with group members through surveys.",
    surveyManagement: "Survey Management",
    overallOrganizationChart: "Organization chart",
    createNewSurvey: "Create New Survey",
    overallSurveyChart: "Result Graph Overview",
  },
  groupManagement: "Group Management",
  groupManagementSubMsg: "Please click “G” button to add members.",
  survey: "Survey",
  surveyList: "Survey list",
  newSurvey: "New survey",
  shareSources: "Share sources",
  manager: "Manager",
  clients: "Clients",
  allSurveyCharts: "Result graph for every question",
  addGroupMember: "Add group member",
  multipleRegistration: "Multiple registration",
  wantToSetLC: "Would you assign {{name}} as LC in {{groupName}}?",
  wantToUnsetLC: "Would you remove {{name}} in {{groupName}} from LC role?",
  idNum: "Employee ID",
  name: "Name",
  managerType: "Manager type",
  contact: "Contact Number",
  responded: "Responded",
  recentResponsed: "Last Response",
  editInfo: "Edit Info",
  assignToLC: "Assign as LC",
  unsetToLC: "Remove from LC role",
  remove: "Delete",
  removeMember: "Delete member",
  move: "Move",
  moveGroup: "Move group",
  changeGroup: "Change Group",
  groupHistory: "Group History",
  departmentHistory: "Department History",
  no: "No",
  date: "Date",
  responsesToSurvey: "Responses to survey",
  source1: "Resource 1",
  source2: "Resource 2",
  source3: "Resource 3",
  resultsWithSteps: "Results (I disagree/I partially disagree/I partially agree/I agree)",
  status: "Status",
  sent: "Sent",
  fianlResponded: "Responded",
  received: "Received",
  result: "Result",
  proceed: "Proceeding",
  completed: "Completed",
  startDateReservation: "Scheduling",
  startDate: "Start Date",
  endDate: "End Date",
  sentAt: "Time",
  chooseGroup: "Group",
  team: "Group",
  contents: "Contents",
  sources: "Resources",
  issue: "Issue",
  addManager: "Add Manager",
  removeManager: "Are you sure you want to delete this manager?",
  emailAlreadyExists: "Email already exists.",
  contactNumberAlreadyExists: "Contact number already exists.",
  company: "Company",
  email: "Email",
  lastAccessTime: "Last access time",
  password: "Password",
  editManager: "Edit Manager",
  clientId: "Client Name",
  addClient: "Add Client",
  deleteClient: "Are you sure you want to delete this client?",
  deleteMember: "Are you sure you want to delete {{name}}?",
  promptNewName: "Please enter a new name.",
  ok: "OK",
  cancel: "Cancel",
  cancelSendSms: "Undo sending",
  expected: "Scheduled",
  createdAt: "Date of creation",
  chooseQuestion: "Please choose a question.",
  chooseQuestionMessage: "Please select at least one question.",
  question1: "I have the information and conditions that I need to be able to perform.",
  question2: "I get feedback on my performance.",
  question3: "We have a straightforward and clear dialogue where everyone is included.",
  question4: "I believe my performance is important to the company.",
  question5: "All of us take responsibility for our common assignment.",
  question6: "I feel that I ”belong to the group”, i.e. I have a good relation with my colleagues and my manager.",
  question7: "We work actively to develop both as a group and as individuals.",
  question8: "Our responsibility and mandate are clear.",
  question9: "We know our customers’ expectations and the value we add.",
  question10: "We work according to the company’s and the group's priorities.",
  question11: "Our meetings lead to the development of our group.",
  question12: "For me, it´s easy to think new ideas and adapt when conditions change.",
  surveyResult: "Survey result",
  totalResult: "Total result",
  resultsByGroup: "Results by group",
  downloadResponderDetails: "Download Responder details",
  lcReport: "LC Report",
  exportLcReport: "Export LC Report",
  memberHistory: "History",
  logout: "Logout",
  averageResult: "Average",
  prevResult: "Previous",
  thisWeekResult: "This week",
  edit: "Edit",
  surveyPeriod: "Survey Period",
  go: "Go",
  login: {
    login: "Login",
    findPassword: "Find password",
    email: "Email",
    password: "Password",
    notValidEmail: "Not valid Email",
    emailIsRequired: "Please enter your email address.",
    passwordIsRequired: "Please enter your password.",
    noManagerFound: "No manager found",
    passwordNotMatches: "Password not matching",
  },
  findpw: {
    findPassword: "Find password",
    sendEmail: "Send email",
    emailSented: "Email has been sent.",
  },
  errors: {
    unknownError: "Unknown error",
    networkError: "network Error",
  },
  managerPage: {
    saveAsSuperAdmin: "Save as super manager",
  },
  editClient: "Edit client",
  clientPage: {
    clientID: "Client ID",
  },
  groupSurveyOfThisWeek: "Group survey of this week",
  notAtAllLikely: "Not at all likely",
  extremelyLikely: "Extremely likely",
  viewResources: "View resources",
  sendSurvey: {
    selectQuestion: "Please select at least one question.",
    selectGroup: "Please select at leasst one group.",
    addQuestion: "Add question",
  },
  yup: {
    required: "Required",
    invalidUrl: "Invalid URL",
  },
}

const resources = {
  en: {
    translation: englishTranslation,
  },
  ko: {
    translation: merge({}, englishTranslation, {
      DinoDialogue: "Dino Dialogue",
      group: "그룹/팀",
      dashboardPage: {
        nthGroupMessage: "총 {{count}}개의 그룹을 관리하고 있습니다.",
        surveyIntroMessage: "설문을 통해 그룹/팀 멤버들의 생각을 공유합니다.",
        surveyManagement: "설문 관리",
        overallOrganizationChart: "전체 조직도",
        createNewSurvey: "새 설문 만들기",
        overallSurveyChart: "결과그래프 전체보기",
      },
      groupManagement: "그룹/팀 관리",
      groupManagementSubMsg: "그룹/팀에 멤버를 추가하려면 “G”버튼을 누르세요.",
      survey: "설문",
      surveyList: "설문 리스트",
      newSurvey: "설문 발송하기",
      shareSources: "자료 공유",
      manager: "매니저",
      clients: "고객사",
      allSurveyCharts: "질문별 결과그래프 확인",
      addGroupMember: "그룹/팀 멤버 추가",
      multipleRegistration: "단체 멤버 추가",
      wantToSetLC: "{{name}} 님을 {{groupName}}의 LC로 임명하시겠습니까?",
      wantToUnsetLC: "{{name}} 님을 {{groupName}}의 LC로 임명 해제하시겠습니까?",
      idNum: "사원번호",
      name: "이름",
      contact: "연락처",
      responded: "참여한 설문",
      recentResponsed: "최근 응답",
      editInfo: "정보수정",
      assignToLC: "LC 임명",
      unsetToLC: "LC 임명 해제",
      remove: "삭제",
      removeMember: "멤버 삭제",
      move: "이동",
      moveGroup: "그룹/팀 이동하기",
      changeGroup: "그룹/팀 변경",
      groupHistory: "그룹/팀 히스토리",
      departmentHistory: "부서 히스토리",
      no: "번호",
      date: "날짜",
      responsesToSurvey: "응답여부",
      source1: "자료 1",
      source2: "자료 2",
      source3: "자료 3",
      resultsWithSteps: "결과 (전혀 그렇지 않다/그렇지 않다/그렇다/매우 그렇다)",
      status: "상태",
      sent: "대상인원",
      fianlResponded: "최종응답인원",
      received: "받은 설문",
      result: "결과",
      proceed: "진행중",
      completed: "완료",
      startDateReservation: "설문기간",
      startDate: "시작일",
      endDate: "종료일",
      sentAt: "발송시간",
      chooseGroup: "그룹/팀 선택",
      team: "그룹/팀",
      contents: "질문",
      sources: "자료공유",
      issue: "발송하기",
      addManager: "새 매니저 추가",
      removeManager: "매니저를 삭제하시겠습니까?",
      emailAlreadyExists: "이미 동록된 이메일이 있습니다.",
      contactNumberAlreadyExists: "이미 등록된 번호가 있습니다.",
      company: "회사",
      email: "이메일",
      lastAccessTime: "마지막 접속시간",
      password: "패스워드",
      editManager: "매니저 정보 수정",
      clientId: "고객사 명",
      addClient: "새 고객사 추가",
      deleteClient: "고객사 정보를 삭제하시겠습니까?",
      deleteMember: "{{name}}님을 삭제하시겠습니까?",
      promptNewName: "수정할 이름을 입력하세요",
      ok: "확인",
      cancel: "취소",
      cancelSendSms: "발송취소",
      expected: "예정",

      createdAt: "생성일",
      chooseQuestion: "질문을 선택해주세요.",
      chooseQuestionMessage: "질문을 선택해주세요.(복수선택 가능)",
      question1: "일을 하기 위해 필요한 정보와 업무 환경이 갖추어져 있다.",
      question2: "내 업무의 결과와 과정에 대한 피드백을 받을 수 있다",
      question3: "모든 팀원이 솔직하고 분명한 의견을 가지고 대화할 수 있다.",
      question4: "내 업무의 성과는 회사에서 중요하게 여겨진다.",
      question5: "그룹/팀의 목표에 대해 모두 책임감을 가지고 있다.",
      question6: "나는 우리 그룹/팀에 소속감과 유대감을 가지고 있다.",
      question7: "그룹/팀에서 주도적으로 일하는 것은 나를 발전시킨다.",
      question8: "우리 그룹/팀은 책임과 의무가 명확하다.",
      question9: "우리 그룹/팀이 하는 일이 고객에게 어떤 가치가 있는지 알고 있다.",
      question10: "회사와 그룹의 우선 순위에 따라 일한다.",
      question11: "회의를 통해 우리 그룹은 발전하고 있다.",
      question12: "우리 그룹/팀은 새로운 환경의 변화에 잘 적응한다.",
      surveyResult: "설문결과",
      totalResult: "종합결과",
      resultsByGroup: "그룹/팀별 결과",
      downloadResponderDetails: "개인별 참여현황 다운로드",
      lcReport: "LC 보고서",
      exportLcReport: "LC 보고서 다운로드",
      memberHistory: "참여내역",
      logout: "로그아웃",
      averageResult: "평균",
      prevResult: "이전 설문결과",
      thisWeekResult: "본 설문결과",
      edit: "수정",
      surveyPeriod: "설문조사 기간",
      go: "시작",
      login: {
        login: "로그인",
        findPassword: "비밀번호 찾기",
        email: "이메일",
        password: "비밀번호",
        notValidEmail: "유효한 이메일이 아닙니다.",
        emailIsRequired: "이메일을 입력하세요.",
        passwordIsRequired: "비밀번호를 입력하세요.",
        noManagerFound: "매니저를 찾을 수 없습니다.",
        passwordNotMatches: "비밀번호가 맞지 않습니다.",
      },
      findpw: {
        findPassword: "비밀번호 찾기",
        sendEmail: "이메일 전송",
        emailSented: "이메일이 전송되었습니다.",
      },
      errors: {
        unknownError: "Unknown error",
        networkError: "network Error",
      },
      managerPage: {
        saveAsSuperAdmin: "Super manager로 지정",
      },
      editClient: "고객사 정보 수정",
      clientPage: {
        clientID: "고객사 ID",
      },
      groupSurveyOfThisWeek: "이번 주 그룹/팀 설문",
      notAtAllLikely: "전혀 그렇지 않다",
      extremelyLikely: "매우 그렇다",
      viewResources: "자료보기",
      sendSurvey: {
        selectQuestion: "질문을 선택해주세요.",
        selectGroup: "메시지를 전송할 그룹/팀을 선택해주세요.",
        addQuestion: "질문 추가하기",
      },
      yup: {
        required: "필수 입력사항입니다.",
        invalidUrl: "유효하지 않는 URL입니다.",
      },
    } as typeof englishTranslation),
  },
  sv: {
    translation: merge({}, englishTranslation, {
      DinoDialogue: "Dino Dialogue",
      group: "Grupp",
      dashboardPage: {
        nthGroupMessage: "Totalt {{count}} grupper är nu under bearbetning.",
        surveyIntroMessage: "Dela åsikter med dina gruppmedlemmar genom att göra en undersökning.",
        surveyManagement: "Undersökningshantering",
        overallOrganizationChart: "Organisationsschema",
        createNewSurvey: "Skapa ny undersökning",
        overallSurveyChart: "Undersökningsdiagram",
      },
      groupManagement: "Gruppindelning",
      groupManagementSubMsg: "Tryck på “G”-knappen för att lägga till deltagare.",
      survey: "Undersökning",
      surveyList: "Lista undersök ningar",
      newSurvey: "Ny mätning",
      shareSources: "Dela källor",
      manager: "Ansvarig",
      clients: "Företag",
      allSurveyCharts: "total undersökningsdiagram",
      addGroupMember: "Lägg till I gruppen",
      multipleRegistration: "Flera registreringar",
      wantToSetLC: "Välj {{name}} som gruppansvarig för {{groupName}}?",
      wantToUnsetLC: "Vill du verkligen ta bort {{name}} I {{groupname}} som gruppledare?",
      idNum: "Id nr",
      name: "Namn",
      contact: "Telefon",
      responded: "Svarat",
      recentResponsed: "Senaste svar",
      editInfo: "Redigera info",
      assignToLC: "Gruppa-nsvaig",
      unsetToLC: "Ta bort som gruppledare",
      remove: "Radera",
      removeMember: "Radera deltagare",
      move: "Flytta",
      moveGroup: "Flytta grupp",
      changeGroup: "Byt grupp",
      groupHistory: "Grupphistorik",
      departmentHistory: "Avdelninghistorik",
      no: "Nummer",
      date: "Datum",
      responsesToSurvey: "Svar på undersökningen",
      source1: "Källa 1",
      source2: "Källa 2",
      source3: "Källa 3",
      resultsWithSteps: "Resultat (Stämmer inte alls/Stämmer delvis/Stämmer ganska väl/Stämmer helt)",
      status: "Status",
      sent: "Skickat",
      fianlResponded: "Svarat",
      received: "Mottaget",
      result: "Resultat",
      proceed: "Fortsätt",
      completed: "Avslutad",
      startDateReservation: "Bokning av startdatum",
      startDate: "Startdatum",
      endDate: "Slutdatum",
      sentAt: "Time Set",
      chooseGroup: "Välj Grupp",
      team: "Grupp",
      contents: "Innehåll",
      sources: "Dela källor",
      issue: "Skicka fråga",
      addManager: "Lägg till ansvarig",
      removeManager: "Är du säker på att du vill ta bort den här chefen?",
      emailAlreadyExists: "E-post finns redan.",
      contactNumberAlreadyExists: "Kontaktnummer finns redan.",
      company: "Kundföretag",
      email: "Email",
      lastAccessTime: "Sista datum för åtkomst",
      password: "Lösenord",
      editManager: "Redigera info",
      clientId: "Kundföretagets namn",
      addClient: "Lägg till ny kund",
      deleteMember: "Är du säker på att du vill ta bort {{name}}?",
      promptNewName: "Skriv in det nya namnet.",
      ok: "OK",
      cancel: "Cancel",
      cancelSendSms: "Ångra sänd",
      expected: "Schemalagd",

      createdAt: "Datum för registrering",
      chooseQuestion: "Välj fråga",
      chooseQuestionMessage: "Välj en fråga att skicka (flera val möjliga)",
      question1: "Jag har den information och de förutsättningar jag behöver för att kunna prestera.",
      question2: "Jag får återkoppling och feedback på min prestation.",
      question3: "Vi har en rak och tydlig kommunikation där alla får ta plats.",
      question4: "Jag upplever att mina prestationer är viktiga för företaget.",
      question5: "Vi tar alla ett ansvar för gruppens gemensamma uppdrag.",
      question6:
        "Jag känner att jag ”tillhör gruppen” dvs jag känner och har tillräcklig kontakt med chef och kollegor?",
      question7: "Vi arbetar aktivt för att utvecklas som grupp och individer.",
      question8: "Det är tydligt för oss vilket ansvar och mandat vi har.",
      question9: "Vi vet vad våra kunder förväntar sig och vilket värde vi tillför.",
      question10: "Vi arbetar efter verksamhetens och gruppens prioriteringar.",
      question11: "Våra möten utvecklar vår grupp.",
      question12: "Jag har lätt att tänka nytt och anpassa mig när förutsättningarna ändras.",
      surveyResult: "resultatat",
      totalResult: "Total resultat",
      resultsByGroup: "Resultat efter grupp",
      downloadResponderDetails: "Ladda hem detaljer om respondenten",
      lcReport: "Handledarrapport",
      exportLcReport: "Exportera grupprapport",
      memberHistory: "Historik",
      logout: "Logga ut",
      averageResult: "Medel",
      prevResult: "Tidigare",
      thisWeekResult: "Denna vecka",
      edit: "Ändra",
      surveyPeriod: "Svarstid",
      go: "Svara",
      login: {
        login: "Login",
        findPassword: "Hitta lösenord",
        email: "Email",
        password: "Lösenord",
        notValidEmail: "Ej giltig e-post",
        emailIsRequired: "Skriv din din e-postadress.",
        passwordIsRequired: "Ange ditt lösenord.",
        noManagerFound: "Ingen chef hittades.",
        passwordNotMatches: "Lösenordet matchar inte.",
      },

      findpw: {
        findPassword: "Hitta lösenord",
        sendEmail: "Skicka epost",
        emailSented: "E-post har skickats.",
      },
      errors: {
        unknownError: "Unknown error",
        networkError: "network Error",
      },
      managerPage: {
        saveAsSuperAdmin: "Spara som super manager",
      },
      editClient: "Redigera info",
      clientPage: {
        clientID: "Kund ID",
      },
      groupSurveyOfThisWeek: "Denna veckas mätning",
      notAtAllLikely: "Stämmer inte alls",
      extremelyLikely: "Stämmer helt",
      viewResources: "Se fil",
      sendSurvey: {
        selectQuestion: "Välj en fråga",
        selectGroup: "Välj en grupp",
        addQuestion: "Lägg till fråga",
      },
      yup: {
        required: "Required",
        invalidUrl: "Invalid URL",
      },
    } as typeof englishTranslation),
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: sessionStorage.getItem("gdlng") || "en",
})

export const langs = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "한국어",
    value: "ko",
  },
  {
    label: "Svenska",
    value: "sv",
  },
]

export default i18n
