import React from "react"
import { Button, Menu, MenuButton, MenuItem, MenuItemOption, MenuList, MenuOptionGroup } from "@chakra-ui/react"
import clsx from "clsx"
import { useQuery } from "@apollo/client"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { usePathClient } from "hooks/usePathClient"
import { useProfile } from "hooks/useProfile"
import { useTranslation } from "react-i18next"
import { Link, NavLink, Route, Switch, useHistory, useParams } from "react-router-dom"
import { langs } from "../i18n"
import "./Layout.scss"
import { GET_CLIENTS_BY_MID } from "gqls"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../store/Store"
import { updateCid } from "../store/reducers/SessionReducer"

function ClientSelect() {
  const profile = useProfile()
  const pathClient = usePathClient()
  const { data, loading, refetch } = useQuery(GET_CLIENTS_BY_MID, {
    variables: { managerId: profile.id },
  })
  const client = pathClient || profile.clients[0]
  const history = useHistory()
  const dispatch = useDispatch()
  const { cid } = useParams<{ cid: string }>()

  if (!cid || !client) {
    return null
  }

  return (
    <Menu onOpen={() => refetch()}>
      <MenuButton
        as={Button}
        isLoading={loading}
        minWidth="280px"
        variant="outline"
        borderColor="gray.400"
        colorScheme="gray"
        textAlign="left"
        rightIcon={<ChevronDownIcon />}
      >
        {client.cid}
      </MenuButton>
      <MenuList minWidth="240px" isLoading={loading}>
        <MenuOptionGroup
          value={client.cid}
          onChange={(cid) => {
            console.log({ cid })
            dispatch(updateCid(cid))
            history.push(`/c/${cid}/dashboard`)
          }}
          type="radio"
        >
          {data?.clients.map((c) => (
            <MenuItemOption key={c.cid} value={c.cid}>
              {c.cid}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

function ClientMenus() {
  const { t } = useTranslation()
  const profile = useProfile()
  const { data } = useQuery(GET_CLIENTS_BY_MID, {
    variables: { managerId: profile.id },
  })
  const defaultClientId = useSelector((state: RootState) => state.session.cid) || data?.clients[0]?.cid
  const { cid = defaultClientId } = useParams<{ cid: string }>()

  return (
    <>
      <Link className={clsx("menu")} to={`/c/${cid}/grouptree`}>
        {t("group")}
      </Link>
      <div>
        <NavLink className={clsx("sub-menu")} to={`/c/${cid}/grouptree`}>
          {t("groupManagement")}
        </NavLink>
      </div>
      <Link className={clsx("menu")} to={`/c/${cid}/survey`}>
        {t("survey")}
      </Link>
      <div>
        <NavLink className={clsx("sub-menu")} to={`/c/${cid}/survey`}>
          {t("surveyList")}
        </NavLink>
        <NavLink className={clsx("sub-menu")} to={`/c/${cid}/send`}>
          {t("newSurvey")}
        </NavLink>
        {/* <Link className={clsx('sub-menu')} to={`/c/${client.cid}/share`}>
              {t('shareSources')}
            </Link> */}
      </div>
    </>
  )
}

function ContentLayout({ children }) {
  return <div>{children}</div>
}

export function Layout({ children }) {
  const { t, i18n } = useTranslation()
  const profile = useProfile()
  const history = useHistory()
  const cid = useSelector((state: RootState) => state.session.cid)

  async function handleLogout() {
    history.replace("/logout")
  }

  return (
    <div className="layout">
      <div className="appbar level">
        <div className="level-left">
          <div
            className="logo level-item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (cid) {
                history.push(`/c/${cid}/dashboard`)
              } else {
                history.push("/dashboard")
              }
            }}
          >
            {t("DinoDialogue")}
          </div>
          <div className="client level-item" style={{ zIndex: 100 }}>
            <Route path="/c/:cid" component={ClientSelect} />
          </div>
        </div>
        <div className="level-right">
          <div className="lang level-item">
            <Menu>
              <MenuButton as={Button} variant="ghost" colorScheme="gray" rightIcon={<ChevronDownIcon />}>
                {langs.find((l) => l.value === i18n.language).label}
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuOptionGroup
                  value={i18n.language}
                  onChange={(lang) => {
                    sessionStorage.setItem("gdlng", lang as string)
                    i18n.changeLanguage(lang as string)
                  }}
                  type="radio"
                >
                  {langs.map((l) => (
                    <MenuItemOption key={l.value} value={l.value}>
                      {l.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </div>
          <div className="search level-item">{/* <input placeholder="search" /> */}</div>
          <div className="auth-user level-item">
            <Menu>
              <MenuButton as={Button} variant="ghost" colorScheme="gray" rightIcon={<ChevronDownIcon />}>
                {profile.name}
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>

      <div className="body">
        <div className="sidebar">
          <Switch>
            <Route path="/c/:cid" component={ClientMenus} />
            <Route component={ClientMenus} />
          </Switch>
          {profile.isSuper && (
            <>
              <NavLink className={clsx("menu")} to={`/clients`}>
                {t("clients")}
              </NavLink>
              <NavLink className={clsx("menu")} to={`/managers`}>
                {t("manager")}
              </NavLink>
            </>
          )}
        </div>
        <div className="right-area">
          <div className="main">{children}</div>
          <div className="copyright">{"© 2020 Paul&Mark. All rights reserved"}</div>
        </div>
      </div>
    </div>
  )
}

export default ContentLayout
