import { combineReducers, createStore } from "redux"
import SessionReducer from "./reducers/SessionReducer"

export interface RootState {
  session: any
}

export default createStore(
  combineReducers({
    session: SessionReducer,
  })
)
