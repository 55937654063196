import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { GET_PROFILE, GET_CLIENTS_BY_MID } from "../gqls"

export function usePathClient() {
  const {
    data: { me },
  } = useQuery(GET_PROFILE, { errorPolicy: "ignore", fetchPolicy: "cache-only" })
  const { data } = useQuery(GET_CLIENTS_BY_MID, {
    fetchPolicy: "cache-only",
    variables: { managerId: me.id },
  })
  const { cid } = useParams<{ cid: string }>()
  const client = (data ? data.clients : me.clients).find((c) => c.cid === cid)
  return client
}
