import { createAction, handleActions } from "redux-actions"

const UPDATE_CID = "UPDATE_CID"

export const updateCid = createAction(UPDATE_CID)

export default handleActions(
  {
    [UPDATE_CID]: (state, { payload: cid }) => Object.assign(state, { cid }),
  },
  {
    cid: "",
  }
)
